var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "live-demo-form"
  }, [!_vm.isDemoRequestSuccessfullySent ? [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'NAME',
      "validation": _vm.$v.formData.name
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'COMPANY',
      "validation": _vm.$v.formData.company
    },
    model: {
      value: _vm.formData.company,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "company", $$v);
      },
      expression: "formData.company"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'PHONE',
      "validation": _vm.$v.formData.phone
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'EMAIL',
      "validation": _vm.$v.formData.email
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  }), _c('sygni-checkbox', {
    staticClass: "secondary no-wrap",
    attrs: {
      "clickableLabel": false,
      "validation": _vm.$v.formData.disclaimer
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.formData.disclaimer.$touch();
      }
    },
    model: {
      value: _vm.formData.disclaimer,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "disclaimer", $$v);
      },
      expression: "formData.disclaimer"
    }
  }, [_c('div', {
    staticClass: "agreement-text"
  }, [_vm._v(" I consent to the processing of my data in accordance with GDPR and declare that I have read the "), _c('span', {
    staticClass: "hoverable-a-element",
    on: {
      "click": _vm.showRegulations
    }
  }, [_vm._v("privacy policy and terms and conditions")])])]), _vm._m(0), _c('sygni-rounded-button', {
    staticClass: "red filled",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.requestDemo();
      }
    }
  }, [_vm._v(" Request demo ")])] : _vm._e(), _vm.isDemoRequestSuccessfullySent ? _c('div', {
    staticClass: "demo-send-success"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/icons/accept.svg")
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v("Demo request has been sent.")]), _c('div', {
    staticClass: "description"
  }, [_vm._v("We will get in touch with you in the next 24h. ")]), _c('sygni-rounded-button', {
    staticClass: "filled black",
    on: {
      "click": _vm.goToHomePage
    }
  }, [_vm._v("Go to home page")])], 1) : _vm._e()], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "security-text"
  }, [_vm._v(" Your connection is protected by SSL "), _c('img', {
    attrs: {
      "src": require("@/assets/icons/padlock.svg")
    }
  })]);
}]

export { render, staticRenderFns }